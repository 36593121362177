import { CircularProgress } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { colors } from '@penji/style-guide';
import React, { Suspense, lazy } from 'react';
import { StyleSheet, View } from 'react-native';

const LazyApp = lazy(() => import('@penji/admin/components/app'));

export const AppLoader = () => {
    return (
        <ThemeProvider theme={theme}>
            <Suspense
                fallback={
                    <View style={styles.progressContainer}>
                        <CircularProgress />
                    </View>
                }
            >
                <LazyApp />
            </Suspense>
        </ThemeProvider>
    );
};

const styles = StyleSheet.create({
    progressContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors['blue-700'],
        },
    },
});
