export const fonts: Record<FontName, Font> = {
    light: {
        fontFamily: 'Muli, sans-serif',
        fontWeight: '300',
    },
    regular: {
        fontFamily: 'Muli, sans-serif',
        fontWeight: '400',
    },
    medium: {
        fontFamily: 'Muli, sans-serif',
        fontWeight: '500',
    },
    'demi-bold': {
        fontFamily: 'Muli, sans-serif',
        fontWeight: '600',
    },
    bold: {
        fontFamily: 'Muli, sans-serif',
        fontWeight: '700',
    },
};

type FontName = 'light' | 'regular' | 'medium' | 'demi-bold' | 'bold';

interface Font {
    fontFamily: string;
    fontWeight: '300' | '400' | '500' | '600' | '700';
}
