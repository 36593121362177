import { colors } from './colors';

export const shadows = {
    light: {
        boxShadow: `0px 1px 2px ${colors['shadow-black']}`,
    },
    medium: {
        boxShadow: `0px 2px 4px ${colors['shadow-black']}`,
    },
    heavy: {
        boxShadow: `0px 4px 8px ${colors['shadow-black']}`,
    },
};
