const APP_MAX_WIDTH = 552;

export const breakpoints = {
    minWidthMd: 960,
    maxWidthMd: 959.98,

    minWidthSm: 600,
    maxWidthSm: 599.98,

    minHeightMd: 540,
    maxHeightMd: 539.98,
};

export const contentWidths = {
    md: {
        default: APP_MAX_WIDTH,
        withDrawer: 912,
    },
    sm: {
        default: APP_MAX_WIDTH,
    },
};

export const drawerWidths = {
    md: 300,
};
