import { colors } from '@penji/style-guide/colors';
import { fonts } from '@penji/style-guide/fonts';

export const textStyles = {
    // Mega

    'mega/normal/gray-600': {
        ...fonts['light'],
        fontSize: 72,
        lineHeight: 96,
        color: colors['gray-600'],
    },

    // Headline-3

    'headline-3/normal/gray-700': {
        ...fonts['regular'],
        fontSize: 24,
        lineHeight: 32,
        color: colors['gray-700'],
    },

    // Heading

    'heading/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 20,
        lineHeight: 22,
        color: colors['gray-700'],
    },

    // Title-2

    'title-2/normal/blue-600': {
        ...fonts['demi-bold'],
        fontSize: 18,
        lineHeight: 24,
        color: colors['blue-600'],
    },

    'title-2/normal/gray-700': {
        ...fonts['demi-bold'],
        fontSize: 18,
        lineHeight: 24,
        color: colors['gray-700'],
    },

    // Title

    'title/normal/white': {
        ...fonts['medium'],
        fontSize: 18,
        lineHeight: 24,
        color: colors['white'],
    },

    'title/normal/blue-600': {
        ...fonts['medium'],
        fontSize: 18,
        lineHeight: 24,
        color: colors['blue-600'],
    },

    'title/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 18,
        lineHeight: 24,
        color: colors['gray-600'],
    },
    'title/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 18,
        lineHeight: 24,
        color: colors['gray-700'],
    },

    // Subheading-2

    'subheading-2/normal/white': {
        ...fonts['demi-bold'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['white'],
    },

    'subheading-2/normal/gray-300': {
        ...fonts['demi-bold'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['gray-300'],
    },
    'subheading-2/normal/gray-600': {
        ...fonts['demi-bold'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['gray-600'],
    },
    'subheading-2/normal/gray-700': {
        ...fonts['demi-bold'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['gray-700'],
    },

    // Subheading

    'subheading/normal/white': {
        ...fonts['medium'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['white'],
    },

    'subheading/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['gray-600'],
    },
    'subheading/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 17,
        lineHeight: 24,
        color: colors['gray-700'],
    },

    // Body-2

    'body-2/normal/white': {
        ...fonts['demi-bold'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['white'],
    },

    'body-2/normal/blue-600': {
        ...fonts['demi-bold'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['blue-600'],
    },

    'body-2/normal/gray-600': {
        ...fonts['demi-bold'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['gray-600'],
    },
    'body-2/normal/gray-700': {
        ...fonts['demi-bold'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['gray-700'],
    },

    // Body

    'body/normal/white': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['white'],
    },

    'body/normal/gray-200': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['gray-200'],
    },
    'body/normal/gray-300': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['gray-300'],
    },
    'body/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['gray-600'],
    },
    'body/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['gray-700'],
    },

    'body/normal/blue-600': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['blue-600'],
    },
    'body/normal/blue-700': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['blue-700'],
    },

    'body/normal/red-500': {
        ...fonts['medium'],
        fontSize: 15,
        lineHeight: 20,
        color: colors['red-500'],
    },

    // Callout-2

    'callout-2/normal/blue-600': {
        ...fonts['demi-bold'],
        fontSize: 14,
        lineHeight: 20,
        color: colors['blue-600'],
    },

    'callout-2/normal/gray-300': {
        ...fonts['demi-bold'],
        fontSize: 14,
        lineHeight: 20,
        color: colors['gray-300'],
    },

    'callout-2/normal/gray-700': {
        ...fonts['demi-bold'],
        fontSize: 14,
        lineHeight: 20,
        color: colors['gray-700'],
    },

    // Callout

    'callout/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 14,
        lineHeight: 20,
        color: colors['gray-600'],
    },
    'callout/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 14,
        lineHeight: 20,
        color: colors['gray-700'],
    },

    // Caption

    'caption/normal/white': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 20,
        color: colors['white'],
    },

    'caption/normal/blue-600': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 20,
        color: colors['blue-600'],
    },

    'caption/normal/gray-300': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 20,
        color: colors['gray-300'],
    },
    'caption/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 20,
        color: colors['gray-600'],
    },
    'caption/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 20,
        color: colors['gray-700'],
    },

    // Caption-2-alt

    'caption-2-alt/normal/gray-700': {
        ...fonts['demi-bold'],
        fontSize: 13,
        lineHeight: 18,
        color: colors['gray-700'],
    },

    // Caption-alt

    'caption-alt/normal/blue-600': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 18,
        color: colors['blue-600'],
    },

    'caption-alt/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 18,
        color: colors['gray-600'],
    },
    'caption-alt/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 13,
        lineHeight: 18,
        color: colors['gray-700'],
    },

    // Small-2

    'small-2/normal/gray-700': {
        ...fonts['demi-bold'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['gray-700'],
    },

    // Small

    'small/normal/white': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['white'],
    },

    'small/normal/blue-600': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['blue-600'],
    },
    'small/normal/blue-700': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['blue-700'],
    },

    'small/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['gray-600'],
    },
    'small/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['gray-700'],
    },

    'small/normal/green-700': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['green-700'],
    },

    'small/normal/red-600': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['red-600'],
    },

    'small/normal/orange-600': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['orange-600'],
    },
    'small/normal/orange-700': {
        ...fonts['medium'],
        fontSize: 12,
        lineHeight: 16,
        color: colors['orange-700'],
    },

    // Smaller

    'smaller/normal/white': {
        ...fonts['medium'],
        fontSize: 11,
        lineHeight: 16,
        color: colors['white'],
    },

    'smaller/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 11,
        lineHeight: 16,
        color: colors['gray-600'],
    },
    'smaller/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 11,
        lineHeight: 16,
        color: colors['gray-700'],
    },

    // Smallest

    'smallest/normal/white': {
        ...fonts['medium'],
        fontSize: 9,
        lineHeight: 12,
        color: colors['white'],
    },

    'smallest/normal/gray-600': {
        ...fonts['medium'],
        fontSize: 9,
        lineHeight: 12,
        color: colors['gray-600'],
    },
    'smallest/normal/gray-700': {
        ...fonts['medium'],
        fontSize: 9,
        lineHeight: 12,
        color: colors['gray-700'],
    },
};
