import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intl';
import 'intl/locale-data/jsonp/en';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import 'scroll-behavior-polyfill';
import 'delayed-scroll-restoration-polyfill';
import { ResizeObserver } from '@juggle/resize-observer';
import { AppRegistry } from 'react-native';
import { AppLoader } from './components/app-loader';
import * as serviceWorker from './serviceWorker';

if ('ResizeObserver' in window === false) {
    window.ResizeObserver = ResizeObserver;
}

AppRegistry.registerComponent('App', () => AppLoader);
AppRegistry.runApplication('App', {
    rootTag: document.getElementById('root'),
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
